import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const Projects = () => (
  <StaticQuery
    query={graphql`
      query {
        art_fast: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_learn: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_ideas: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="projects">
        <Container>
          <Grid>
            <div>
              <h2>Datin Dash</h2>
              <p>
                Datin Dash is a full-stack MERN application aimed to track a dater's dates
                over time. It aims to be the single-source-of-truth for a
                modern, mobile dater. Users can add matches, which app they
                matched from, and keep vital information in notes. It's always
                awkward to forget what someone's job is when you're talking to
                them. Now there's no excuse.
                <br />
                <a href="https://datin-dash.herokuapp.com/" target="_blank" rel="noopener noreferrer">
                  Live
                </a>
                {' | '}
                <a
                  href="https://github.com/johnsonvo/datin-dash"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source
                </a>
              </p>
            </div>
            <Art>
              <Img fluid={data.art_fast.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_learn.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Event Booker</h2>
              <p>
                Event Booker is a React application with a Node-GraphQL backend.
                Users can create and book events, while also being allowed to
                view a list of events while not logged-in. This was an
                experiment in learning GraphQL as well as playing with React.
                <br />
                <a
                  href="https://event-booker-graphql.herokuapp.com/events"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Live
                </a>
                {' | '}
                <a
                  href="https://github.com/johnsonvo/graphql-react-event-booking-api"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source
                </a>
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Portfolio Website</h2>
              <p>
                Is it weird if I list the site you're looking at as a project?
                This site was built with Gatsby, using a fantastic <a
                  href="https://www.gatsbyjs.org/starters/ajayns/gatsby-absurd/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  starter
                </a>. It was an
                experiment in learning Gatsby and GraphQL, while also trying to
                build something nice to look at.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_ideas.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default Projects;
