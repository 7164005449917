import React from 'react';

import { Section, Container } from '@components/global';

import FaqItem from '@common/FaqItem';


const FAQS = [
  {
    title: 'Where and what did you study?',
    content: () => (
      <>
        I studied architecture and city planning at UC Berkeley, where I learned
        intentional design and proper research. I got interested in programming
        from a city planning course, which then turned into my attending General
        Assembly's Software Engineering Immersive.
      </>
    ),
  },
  {
    title: 'What sales experience do you have?',
    content: () => (
      <>
        My first foray in sales taught me about customer relationships. My
        second foray taught me the power of CRM, asking good questions during
        discovery, and preparedness in demos.
      </>
    ),
  },
  {
    title: 'Any programming skills?',
    content: () => (
      <>
        General Assembly's Software Engineering Immersive taught us RESTful
        APIs, programmatic thinking, and working under deadlines. We learned
        JavaScript, Python, HTML/CSS, NoSQL, and SQL. We used Node, Express, and
        MongoDB as our tools.
      </>
    ),
  },
  {
    title: 'Why do you want to be a sales engineer?',
    content: () => (
      <>
        I love that SEs program but are also client-facing. I feel that my best
        quality is interfacing with prospects and uncovering their needs, while
        also trying to find the best solution for them.
      </>
    ),
  },
  {
    title: 'Is Johnson really your first name?',
    content: () => (
      <>
        It is! There are less than 5,000{' '}
        <a
          href="https://www.wolframalpha.com/input/?i=first+name+johnson"
          target="_blank"
          rel="noopener noreferrer"
        >
          Johnsons
        </a>{' '}
        alive today. I wish I could take credit for the name, but I think my dad
        wanted to keep a specifically British tradition in my non-British family.
      </>
    ),
  },
];

const Faq = () => (
  <Section id="faq">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Frequently Asked Questions</h1>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </Container>
  </Section>
);

export default Faq;
